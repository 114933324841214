.footer {
  background-color: white;
  text-align: center;
  border-top: 1px solid #ebebef;
  background-color: #f9f9fb;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0 0.5rem;
}

.space {
  height: 80px;
  width: 100%;
}

.footer > p {
  color: #84889a;
  font-size: 12px;
}

.footer > p > a {
  text-decoration: underline;
  cursor: pointer;
}
